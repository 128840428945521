(function ( $ ) {

	$( ".js-modal" ).each(function () {
		var self = $( this );

		if ( this.nodeName === "A" ) {
			self.lightcase( $.extend({
				maxWidth: 1200,
				maxHeight: 1200,
				shrinkFactor: 0.95
			}, self.data() ) );
		} else {
			self.find( "a" ).filter(function () {
				return /\.(jpe?g|gif|png|webp|tiff)([\?#].*)?$/.test( this.href );
			})
			.attr( "data-rel", "lightcase:gallery" )
			.lightcase({
				type: "image",
				maxWidth: 1200,
				maxHeight: 1200,
				shrinkFactor: 0.95
			});
		}
	});

})( jQuery );