(function ( $ ) {

	$( document.body ).on( "click", ".js-load", function ( ev ) {
		ev.preventDefault();

		var self = $( this ).fadeOut();

		$.ajax({
			url: self[0].href,
			method: "GET",
			success: function ( result ) {
				result = $( "<div />" ).html( $.parseHTML( result ) );

				var selector = self.attr( "data-load" );
				var items = result.find( selector ).children();
				var next = result.find( ".js-load[data-load='" + selector + "']" );

				if ( items.length ) {
					$( document.body ).find( selector ).append( items.hide() ).offset();

					items.fadeIn();

					if ( window.history.replaceState ) {
						var title = result.find( "title" ).text();
						document.title = title;
						window.history.replaceState( null, title, self[0].href );
					}
				}

				self.promise().done(function () {
					if ( next.length ) {
						self.replaceWith( next.hide() );
						next.fadeIn();
					} else {
						self.remove();
					}

					self = null;
				});
			},
			error: function () {
				self.promise().done(function () {
					self.remove();
					self = null;
				});
			}
		});
	});

})( jQuery );