(function ( $, undefined ) {

	var menus = $( ".js-menu" ).on( "menu", "li:has(ul)", function ( ev, args ) {
		var self = $( ev.delegateTarget );
		var item = $( this );
		var menu = item.children( "ul" );
		var open = args.open !== undefined ? args.open : ! item.hasClass( "is-open" );

		if ( open ) {
			self.find( "li.is-open" ).removeClass( "is-open" );
		}

		item.toggleClass( "is-open", open );

		if ( args.toggle ) {
			menu.transitionHeight( open ? 0 : menu.outerHeight(), open ? menu.prop( "scrollHeight" ) : 0 );
		} else {
			var body = $( document.body ).off( "click.menu" );
			if ( open ) {
				body.on( "click.menu", function ( ev ) {
					if ( ! self.find( ev.target ).length ) {
						self.find( "li.is-open" ).removeClass( "is-open" );
						body.off( "click.menu" );
					}
				});
			}
		}
	})

	/**
	 * Handle link clicks.
	 */
	.on( "click", "li:has(ul) > a", function ( ev ) {
		var item = $( this ).parent();

		if ( ! item.hasClass( "is-open" ) && ! item.children( ".js-menu-toggle" ).is( ":visible" ) ) {
			ev.preventDefault();

			item.trigger( "menu", {
				open: true
			});
		}
	})

	/**
	 * Handle toggles.
	 */
	.on( "click", ".js-menu-toggle", function ( ev ) {
		$( this ).closest( "li" ).trigger( "menu", {
			toggle: true
		});
	})

	/**
	 * Handle hover reveals (for mouse only).
	 */
	.on( "MSPointerOver pointerover touchstart mouseenter mouseleave", "li:has(ul)", function ( ev ) {
		var touched = $.data( this, "touched" );
		var event   = ev.originalEvent;

		if ( ev.type === "mouseenter" || ev.type === "mouseleave" ) {
			var item = $( this );

			if ( ! touched && ! item.children( ".js-menu-toggle" ).is( ":visible" ) ) {
				item.trigger( "menu", {
					open: ev.type === "mouseenter"
				});
			}

			touched = false;
		} else if ( ev.type === "touchstart" || window.TouchEvent && event instanceof TouchEvent ) {
			touched = true;
		} else if ( event.pointerType ) {
			touched = event.pointerType !== ( event.MSPOINTER_TYPE_MOUSE !== undefined ? event.MSPOINTER_TYPE_MOUSE : "mouse" );
		} else {
			touched = false;
		}

		$.data( this, "touched", touched );
	});

	/**
	 * Append toggles.
	 */
	menus.find( "li:has(ul) > a" ).after( "<button class='js-menu-toggle' />" );

})( jQuery );