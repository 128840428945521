(function ( $, window, undefined ) {

	$.fn.transitionHeight = function ( from, to ) {
		var self = this;

		if ( from == to ) {
			return self;
		}

		var raf = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.msRequestAnimationFrame || window.oRequestAnimationFrame;
		var transition;

		if ( raf ) {
			var transitions = {
				transition      : "transitionend",
				OTransition     : "oTransitionEnd",
				WebkitTransition: "webkitTransitionEnd",
				MozTransition   : "transitionend"
			};

			for ( var name in transitions ) {
				if ( document.body.style[ name ] !== undefined ) {
					transition = name;
					break;
				}
			}
		}

		if ( transition ) {
			self.css( transition, "none" );

			raf(function () {
				self.css( transition, "" );
				self.css({
					overflow: "hidden",
					display: "block",
					height: from
				});

				raf(function () {
					self.css( "height", to ).one( transitions[ transition ], function () {
						$( this ).css({
							overflow: "",
							display: "",
							height: ""
						});
					});
				});
			});
		}

		return self;
	};

})( jQuery, this );