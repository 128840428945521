(function ( $ ) {

	$( ".js-toggle" ).on( "click", function ( ev ) {
		var target = $( this.hash || this.getAttribute( "data-toggle" ) );
		if ( ! target.length ) {
			return;
		}

		ev.preventDefault();

		var open = ! target.hasClass( "is-open" );

		target.add( this ).toggleClass( "is-open", open );
		target.transitionHeight( open ? 0 : target.outerHeight(), open ? target.prop( "scrollHeight" ) : 0 );
	});

})( jQuery );