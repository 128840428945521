(function ( $ ) {

	$( "img.u-sizeCover, img.u-sizeContain" ).each(function () {
		if ( ! ( "object-fit" in this.style ) ) {
			$( this ).hide().wrap( "<div />" ).parent().addClass( this.className ).css({
				backgroundImage: "url(%s)".replace( "%s", this.currentSrc || this.src )
			});
		}
	});

})( jQuery );